.user {
  position: relative;
  background-color: #0B0C0E;

  .userBlur {
    position: absolute;
    width: 100%;
    height: 120%;
    filter: blur(50px);
    background-size: 100% 100%;
    //z-index: -1;
    pointer-events: none;
    opacity: 0.4;
    top: -52px;
  }
}

.input {
  --color: var(--T2);
  border-radius: 4px;
  padding: 12px;
  margin: 16px 0;
  border: 1px solid #5b5f67;
  font-size: 14px;
  font-weight: bold;
  caret-color: #ff6900;

  input {
    caret-color: #ff6900;
    &::placeholder {
      color: #9FA5AC;
    }
  }

  /* For Webkit based browsers */
  input::-webkit-input-placeholder {
    caret-color: #ff6900;
  }

  /* For Firefox */
  input:-moz-placeholder {
    caret-color: #ff6900;
  }

  /* For older versions of Firefox */
  input::-moz-placeholder {
    caret-color: #ff6900;
  }

  /* For Internet Explorer */
  input:-ms-input-placeholder {
    caret-color: #ff6900;
  }
}
